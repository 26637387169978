import { Box, CircularProgress } from "@mui/material";
import useAppContext from "../../context/AppContext";
import { Text } from "./typography";

const LoadingSpinner = () => {
  const { loading, loadingText = "loading" } = useAppContext();

  if (!loading) return null;

  return (
    <Box
      sx={{
        background: "rgba(255, 255, 255, 0.5)",
        width: "100%",
        height: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        zIndex: 9999,
      }}
    >
      {loadingText ? (
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <Text sx={{ fontSize: "1.3rem", fontWeight: "600", margin: 0 }}>
            {loadingText}
          </Text>
        </Box>
      ) : null}
      <CircularProgress size={140} />
    </Box>
  );
};

export default LoadingSpinner;
