import React from "react";
import { Alert } from "@mui/material";

const EventsPanel = ({ events = [] }) => {
  if (events.length < 1) return null;

  return events.map((msg = {}, idx) => {
    const {
      message,
      status,
      details: { count, numberOfNewItems, results = [] } = {},
    } = msg;

    // TODO: add number of items that would be updated to the message (coordinate with backend)

    return (
      <>
        {numberOfNewItems > -1 ? (
          <Alert
            key={`${idx}-new-items`}
            severity="info"
            sx={{ marginBottom: "0.25rem" }}
          >
            {numberOfNewItems > 0
              ? `There ${
                  numberOfNewItems > 1 ? "are" : "is"
                } ${numberOfNewItems} new
            item${numberOfNewItems > 1 ? "s" : ""} in the CSV.`
              : "No new items found in the CSV."}
          </Alert>
        ) : null}
        <Alert key={idx} severity={status} sx={{ marginBottom: "0.25rem" }}>
          {message}
          {results?.length > 0 ? (
            <ul>
              {results.map((r, idx) => (
                <li key={`${idx}-itm-result`}>{r}</li>
              ))}
            </ul>
          ) : null}
        </Alert>
        {count ? (
          <Alert
            key={`${idx}-new-items`}
            severity="info"
            sx={{ marginBottom: "0.25rem" }}
          >
            <p>Summary</p>
            <hr />
            {Object.keys(count).map((val) => (
              <p>
                {val}: {count[val]}
              </p>
            ))}
          </Alert>
        ) : null}
      </>
    );
  });
};

export default EventsPanel;
