import { createContext, useContext, useState } from "react";

const AppContext = createContext(null);

export const AppProvider = ({ children }) => {
  const [loading, setLoading] = useState(false);
  const [loadingText, setLoadingText] = useState("");

  const setLoadingWithText = (loading, text) => {
    setLoading(loading);
    setLoadingText(text);
  };

  const clearLoading = () => {
    setLoading(false);
    setLoadingText("");
  };

  return (
    <AppContext.Provider
      value={{ loading, loadingText, setLoadingWithText, clearLoading }}
    >
      {children}
    </AppContext.Provider>
  );
};

export default function useAppContext() {
  return useContext(AppContext);
}
