import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";

import App from "./components/App";
import { CollectionsProvider } from "./context/CollectionsContext";
import { ItemsProvider } from "./context/ItemsContext";
import Auth0ProviderWrapper from "./context/Auth0ProviderWrapper";

import "./styles/index.css";
import { AppProvider } from "./context/AppContext";
import LoadingSpinner from "./components/common/LoadingSpinner";

const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  <React.StrictMode>
    <BrowserRouter>
      <Auth0ProviderWrapper>
        <AppProvider>
          <CollectionsProvider>
            <ItemsProvider>
              <LoadingSpinner />
              <App />
            </ItemsProvider>
          </CollectionsProvider>
        </AppProvider>
      </Auth0ProviderWrapper>
    </BrowserRouter>
  </React.StrictMode>
);
