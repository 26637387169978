import * as React from "react";
import { Box, Button } from "@mui/material";

const buttonStyles = {
  margin: "0.5rem",
};

const ActionBar = ({
  handleAbort,
  handleConfirm,
  handleResetImport,
  hasErrors,
  status,
}) => (
  <Box
    sx={{
      display: "flex",
      justifyContent: "center",
      borderTop: "1px solid #ccc",
      borderBottom: "1px solid #ccc",
      padding: "0.5rem 0",
    }}
  >
    {["aborted", "imported"].includes(status) ? (
      <Button sx={buttonStyles} onClick={handleResetImport} variant="contained">
        Upload another CSV
      </Button>
    ) : (
      <>
        <Button
          disabled={["validating", "importing"].includes(status)}
          sx={{ ...buttonStyles, backgroundColor: "red", color: "white" }}
          onClick={handleAbort}
          variant="contained"
        >
          Abort
        </Button>
        <Button
          disabled={hasErrors || status !== "validated"}
          sx={buttonStyles}
          onClick={handleConfirm}
          variant="contained"
        >
          Proceed with Import
        </Button>
      </>
    )}
  </Box>
);

export default ActionBar;
