import React from "react";
import { Alert, Box } from "@mui/material";
import { FilePond, registerPlugin } from "react-filepond"; // https://pqina.nl/filepond/docs/
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";

import { MdFileUploadOff } from "react-icons/md";

import ImportProgress from "./ImportProgress";
import { H3 } from "./common/typography";

import {
  abortImport,
  getFilePondServerSettings,
  validate,
} from "../utils/fetch.utils";

import "filepond/dist/filepond.min.css";
import useAppContext from "../context/AppContext";

registerPlugin(FilePondPluginFileValidateType, FilePondPluginFileValidateSize);

const Import = ({ collectionId, disabled }) => {
  const { clearLoading, setLoadingWithText } = useAppContext();

  const [pondKey, setPondKey] = React.useState(Date.now());
  const [events, setEvents] = React.useState([]);
  const [hasErrors, setHasErrors] = React.useState(false);

  const [status, setStatus] = React.useState("idle");
  const [files, setFiles] = React.useState([]);
  const [message, setMessage] = React.useState({});
  const [filename, setFilename] = React.useState(null);

  const filePondServerSettings = getFilePondServerSettings(collectionId);

  React.useEffect(() => {
    if (events.length) {
      setHasErrors(events.some((e) => e.status === "error"));
    }
  }, [events]);

  const handleAddEvent = React.useCallback((events = []) => {
    setEvents((prevState) => [...prevState, ...events]);
  }, []);

  const handleTriggerValidation = async (filename) => {
    setLoadingWithText(true, "Validating");
    setStatus("validating");
    const resp = await validate(collectionId, filename);
    handleAddEvent(resp.results);
    setStatus("validated");
    clearLoading();
  };

  const handleResetImport = () => {
    setStatus("idle");
    setEvents([]);
    setFiles([]);
    setMessage({});
    setFilename(null);
    setPondKey(Date.now());
  };

  const handleRemoveFile = async (err) => {
    if (err) {
      setMessage({
        status: "error",
        message: err.message,
      });
    } else {
      await abortImport(collectionId, filename);
      handleResetImport();
    }
  };

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <H3>Import Items</H3>
      {disabled ? (
        <Alert severity="warning">
          Please export the collection to enable the Import Feature.
        </Alert>
      ) : (
        <Alert severity="success">Yay! Import is now enabled.</Alert>
      )}
      <Box
        sx={{
          border: "3px dashed #ccc",
          padding: "2rem 2rem 1rem",
          margin: "2rem 0 2rem",
          minHeight: "145px",
        }}
      >
        {!disabled ? (
          <FilePond
            key={pondKey} // reset the component when the key changes
            {...{
              files,
              name: "csvfile",
              maxFiles: 1,
              maxFileSize: "5MB",
              acceptedFileTypes: ["text/csv"],
              onupdatefiles: setFiles,
              onremovefile: handleRemoveFile,
              labelIdle:
                'Drag & Drop your CSV file or <span class="filepond--label-action">Browse</span>',
              labelTapToCancel: "Abort",
              labelTapToUndo: "Abort",
              credits: false,
              server: {
                url: filePondServerSettings.url,
                process: {
                  headers: filePondServerSettings.headers,
                  onload: (res) => {
                    setMessage(JSON.parse(res));
                    setFilename(JSON.parse(res).filename);
                    setStatus("cached");
                    handleTriggerValidation(JSON.parse(res).filename);
                  },
                },
              },
            }}
          />
        ) : (
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              fontSize: "4.5rem",
              marginTop: "0",
              textAlign: "center",
              opacity: 0.5,
            }}
          >
            <MdFileUploadOff />
          </Box>
        )}
      </Box>
      {message?.status ? (
        <Alert severity={message.status === 200 ? "success" : "error"}>
          {message.message}
        </Alert>
      ) : null}
      {!disabled && Boolean(filename) ? (
        <>
          <ImportProgress
            {...{
              collectionId,
              events,
              filename,
              handleAddEvent,
              handleResetImport,
              hasErrors,
              status,
              setStatus,
            }}
          />
        </>
      ) : null}
    </Box>
  );
};

export default Import;
