import * as React from "react";
import { Box } from "@mui/material";

import { H3 } from "../common/typography";
import ActionBar from "./ActionBar";
import EventsPanel from "./EventsPanel";

import {
  abortImport,
  confirmImport,
  regenerateOptions,
} from "../../utils/fetch.utils";
import useAppContext from "../../context/AppContext";

const ImportProgress = ({
  collectionId,
  events,
  handleAddEvent,
  handleResetImport,
  hasErrors,
  filename,
  status,
  setStatus,
}) => {
  const { clearLoading, setLoadingWithText } = useAppContext();

  const handleAbort = async () => {
    const resp = await abortImport(collectionId, filename);
    handleAddEvent([resp]);
    setStatus("aborted");
  };

  const handleConfirm = async () => {
    setLoadingWithText(true, "Importing");

    const resp = await confirmImport(collectionId, filename);
    handleAddEvent([resp]);

    setLoadingWithText(true, "Finalizing");
    await regenerateOptions(collectionId);
    setStatus("imported");
    clearLoading();
  };

  return (
    <Box sx={{ margin: "0 0 1rem" }}>
      <H3>Import Progress -- status: {status}</H3>
      <EventsPanel {...{ events }} />
      <ActionBar
        {...{
          handleAbort,
          handleConfirm,
          handleResetImport,
          hasErrors,
          status,
        }}
      />
    </Box>
  );
};

export default ImportProgress;
